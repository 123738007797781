/* eslint-disable no-param-reassign */
import { createSortNumeric } from 'aegion_common_utilities/lib/Sort';
import { sortSurveysByType } from '../../../../utils/surveys.sort/base';

/**
 * HELPERS
 */

const isDefined = v => v !== undefined && v !== null;

export const createLineKey = (prefix, lineagg) => {
	return `${prefix}-${lineagg.customerId}-${lineagg.lineName}`;
};

const convertDateToEpoch = (dt) => { 
  if (!isDefined(dt)) { 
    return -1;
  }

  return new Date(dt).getTime();
}

const sortBySortEpoch = createSortNumeric('DESC', {
  invalidLast: true,
  getValue: (s) => s.sortEpoch
});

const flipIfLastNameIsFirst = name => {
  if (!name) { 
    return name;
  }
  const partsName = name.split(',').map(v => v.trim());
  partsName.reverse();
  return partsName.join(' ');
}



/**
 * HELPERS - SURVEYS
 */

const formatSurveys = surveys => {
  return (surveys || [])
    .map(s => {
      const survey = {
        ...s,
        totalCount: s.total_count,
        bcCount: s.bc_count,
        bcPercentage: s.bc_percentage,
        sortEpoch: Math.max(convertDateToEpoch(s?.start), convertDateToEpoch(s?.end))
      }

      delete survey.bc_percentage;
      delete survey.total_count;
      delete survey.bc_count;

      return survey;
    });
};


/**
 * HELPERS - JOB
 */

const formatJob = job => {
  if (!job) { 
    return job;
  }

  const res = {
    ...job,
    jobNumber: job.jobNumber || job.job,
    entityId: job.entity_id,
    entityName: job.entity_name,
    areaId: job.area_id,
    areaName: job.area_name,
    pipeId: job.pipe_id,
    pipeName: job.pipe_name,
    regulatoryStatusId: job.regulatory_status_id,
    regulatoryStatusName: job.regulatory_status_name,
  };
  
  delete res.job;
  delete res.entity_id;
  delete res.entity_name;
  delete res.area_id;
  delete res.area_name;
  delete res.pipe_id;
  delete res.pipe_name;
  delete res.regulatory_status_id;      
  delete res.regulatory_status_name;

  res.surveys = formatSurveys(job.surveys);
  res.surveys.sort(sortBySortEpoch);
  res.sortEpoch = isDefined(res.surveys[0]?.sortEpoch) ? res.surveys[0]?.sortEpoch : -1;
  res.start = isDefined(res.surveys[0]?.start) ? res.surveys[0]?.start : undefined;
  res.end = isDefined(res.surveys[0]?.end) ? res.surveys[0]?.end : undefined;
  res.surveys.sort(sortSurveysByType);
  res.miles = res.surveys[0]?.miles;
  res.totalCount = res.surveys[0]?.totalCount;
  res.bcCount = res.surveys[0]?.bcCount;
  res.bcPercentage = res.surveys[0]?.bcPercentage;
  return res;
};

const pushUniqueValue = (v, arr, refSet) => {   
  if (isDefined(v) && !refSet.has(v)) {
    refSet.add(v)
    arr.push(v);
  }
}

const liftJobProps = (line) => {
  const refJobNumbers = new Set([]);
  const arrJobNumbers = [];

  const refEntities = new Set([]);
  const arrEntities = [];

  const refAreas = new Set([]);
  const arrAreas = [];

  const refPipeIDs = new Set([]);
  const arrPipeIDs = [];

  const refRegStatuses = new Set([]);
  const arrRegStatuses = [];

  const refCTs = new Set([]);
  const arrCTs = [];

  const refPMs = new Set([]);
  const arrPMs = [];

  const refSurveyTypes = new Set([]);
  const arrSurveyTypes = [];

  (line?.jobs || []).forEach(j => {
    pushUniqueValue(j?.jobNumber, arrJobNumbers, refJobNumbers);
    pushUniqueValue(j?.entityName, arrEntities, refEntities);
    pushUniqueValue(j?.areaName, arrAreas, refAreas);
    pushUniqueValue(j?.pipeName, arrPipeIDs, refPipeIDs);
    pushUniqueValue(j?.regulatoryStatusName, arrRegStatuses, refRegStatuses);
    pushUniqueValue(flipIfLastNameIsFirst(j?.ct), arrCTs, refCTs);
    pushUniqueValue(flipIfLastNameIsFirst(j?.pm), arrPMs, refPMs);
    (j?.surveys || []).forEach(s => {
      pushUniqueValue(s?.type, arrSurveyTypes, refSurveyTypes);
    });
  });

  line.jobNumbers = arrJobNumbers.join('~');
  line.entities = arrEntities.join('~');
  line.areas = arrAreas.join('~');
  line.pipeIDs = arrPipeIDs.join('~');
  line.regulatoryStatuses = arrRegStatuses.join('~');
  line.cts = arrCTs.join('~');
  line.pms = arrCTs.join('~');
  line.surveyTypes = sortSurveysByType(arrSurveyTypes).join('~');
  line.miles = line.jobs[0]?.miles;
  line.totalCount = line.jobs[0]?.totalCount;
  line.bcCount = line.jobs[0]?.bcCount;
  line.bcPercentage = line.jobs[0]?.bcPercentage;
}

/**
 * MAIN
 */

export const createTableItemAndKey = lineagg => {
  if (!lineagg) { 
    return lineagg;
  }
  
  const key = createLineKey('tbl-row', lineagg);
  const tableItem = {
    tblRowKey: key,
    ...lineagg
  };

  tableItem.jobs = (tableItem.jobs || []).map(j => formatJob(j));
  tableItem.jobs.sort(sortBySortEpoch);
  liftJobProps(tableItem);

  return tableItem;
}

export default {
  createTableItemAndKey
};