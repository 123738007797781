import { getCustomer } from "../../../../../util/user";

export const initialState = {
  customer: getCustomer(),
  filters: {},
	isLoading: false,
	isLoaded: false,
	errorMessage: undefined
};

export default initialState;
