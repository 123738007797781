/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';

// LINES AGGREGATES
import { ROOT_SLICE as ROOT_SLICE_LINES_AGGS } from './slices/LinesAggs/constants';
import { initialState as LinesAggs } from './slices/LinesAggs/initialState';
import LinesAggsReducerParts from './slices/LinesAggs/reducerParts';

// LINES AGGREGATES FILTERS
import { ROOT_SLICE as ROOT_SLICE_LINES_AGGS_FILTERS } from './slices/LinesAggsFilters/constants';
import { initialState as LinesAggsFilters } from './slices/LinesAggsFilters/initialState';
import LinesAggsFiltersReducerParts from './slices/LinesAggsFilters/reducerParts';


const initialState = {
	[ROOT_SLICE_LINES_AGGS]: {
		...LinesAggs
  },
	[ROOT_SLICE_LINES_AGGS_FILTERS]: {
		...LinesAggsFilters
	}  
};

const reducerParts = {
  ...LinesAggsReducerParts,
  ...LinesAggsFiltersReducerParts,
};

export { ROOT_SLICE } from './constants'
export default handleActions(reducerParts, initialState);
