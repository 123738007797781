/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { set, setCustomer, setIsLoadingLoaded, clear } from './actions';
import { fetchLinesAggsFilters } from '../../../api/linesaggsfilters';
import { selectCustomer } from '../selectors';

const thunkLoad = () => (dispatch, getState) => {
  const customerName = selectCustomer(getState());

  dispatch(clear());
  dispatch(setCustomer(customerName));
	dispatch(
		setIsLoadingLoaded({
			isLoading: true,
			isLoaded: false
		})
	);
  fetchLinesAggsFilters(customerName).then((filters) => {
		dispatch(set(filters));
		dispatch(
			setIsLoadingLoaded({
				isLoading: false,
				isLoaded: true
			})
		);
	});
};

const thunkSetCustomerName = (customerName) => (dispatch) => { 
  dispatch(setCustomer(customerName));
  dispatch(thunkLoad());
}

export { thunkLoad, thunkSetCustomerName };
